<template>
  <section class="page-ireland">
    <div class="banner">
      <img
        class="logo"
        src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/us/icon.png"
      />
      <p class="title">
        <span>JWP U.S. FUND I</span>
      </p>
    </div>
    <div class="content" :class="{ fold: isFold }">
      <p>
        JWP U.S. FUND I is an immigration fund established under the EB-5 Reform
        and Integrity Act of 2022.EB-5 is a U.S. immigration program, the fifth
        preference visa for immigrant investors, with a quota of 10,000 per
        year. As long as the investor meets the investment conditions set by the
        federal government and invests the funds in an approved investment fund
        or commercial project, he can obtain a U.S. green card and become a
        lawful permanent resident of the United States. Under the new policy,
        $800,000 will be invested in a designated TEA (Targeted Employment Area)
        to create 10 jobs and maintain for two years.
      </p>
    </div>
    <div class="look-more">
      <div class="opacity" v-if="isFold">      
      </div>
      <div class="btn" @click="handleLookMore">
        <span>{{ btnText }}</span>
        <img
          :src="
            isFold
              ? '//static.hoolihome.com/global-jwp/assets/m/arrow-more-down.png'
              : '//static.hoolihome.com/global-jwp/assets/m/arrow-more-up.png'
          "
        />
      </div>
    </div>
    <div class="textcontent">
      <img
        src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/us_1.png"
      />
      <div class="text">New policy of EB-5 from USCIS</div>
    </div>

    <div class="features">
      <p class="title">
        <span class="van-hairline--bottom">FUND Elements</span>
      </p>
      <van-image
        class="img"
        lazy-load
        src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/us/us_7.png?1"
      />
      <p class="title">
        <span class="van-hairline--bottom">FUND Structure</span>
      </p>
      <van-image
        class="img"
        lazy-load
        src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/us/us_8.png"
      />
    </div>
    <div class="duty">
      <p class="title">
        <span
          >Responsibilities from
          <br />
        </span>
        <span class="van-hairline--bottom"> Each Parties under EB-5 </span>
      </p>
      <div class="list">
        <div class="item">
          <div class="name">
            U.S. Citizenship and Immigration Services (USCIS)
          </div>
          <div class="intro">
            It is the government agency that administers the EB-5 program. It is
            responsible for setting guidelines and overseeing compliance for the
            various entities participating in the program, including EB-5
            Regional Centers, new commercial enterprises, job-creating entities,
            sponsors, and investors.
          </div>
        </div>
        <div class="item">
          <div class="name">LP Investor (Client)</div>
          <div class="intro">
            Invests $800,000 in the TEA region to obtain a U.S. green card.The
            investment period is 2-5 years, with an expected annual return of 1%
          </div>
        </div>
        <div class="item">
          <div class="name">General Partner</div>
          <div class="intro">
            The GP is responsible for all record keeping required on behalf of
            the investor and the capital invested and the returns generated -
            annual accounting, annual tax returns, financial reporting and all
            the duties expected of the general partner to oversee the investment
            and keep the investor up to date.
          </div>
        </div>
        <div class="item">
          <div class="name">Regional Center</div>
          <div class="intro">
            An EB-5 Regional Center is a public or private entity in the United
            States involved in promoting economic growth. USCIS designates
            Regional Centers to participate in the Immigrant Investor Program.
          </div>
        </div>
        <div class="item">
          <div class="name">New Commercial Enterprise</div>
          <div class="intro">
            The management of NCE is responsible for ensuring that the business
            operates in compliance with all relevant laws and regulations,
            manages the funds invested by EB-5 investors, and creates jobs in
            accordance with the requirements of the EB-5 program. Given the
            importance of job creation to the EB-5 program, NCE management plays
            a critical role in ensuring that the business is structured to
            maximize job creation and meets the job creation requirements set
            forth by USCIS. In addition, NCE management is responsible for
            providing USCIS with the necessary documentation and evidence that
            the job creation requirements have been met.
          </div>
        </div>
        <div class="item">
          <div class="name">Job Creating Entity</div>
          <div class="intro">
            A JCE is a business entity responsible for creating and maintaining
            the required number of jobs (10) for EB-5 investors. A JCE can be
            any for-profit entity, such as a corporation, limited liability
            company, or partnership, that conducts lawful business activities
            that create jobs in the United States.
          </div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <p class="title">
        <span class="van-hairline--bottom">FUND Advantages</span>
      </p>
      <div class="list">
        <div class="item">
          <div class="left"><div class="icon">1</div></div>
          <div class="right">
            Diversified fund model, diversified investment, reduced investment
            risk, recognized and approved by the immigration authorities
          </div>
        </div>
        <div class="item">
          <div class="left"><div class="icon">2</div></div>
          <div class="right">
            Maintains control of the project, participates in major project
            decisions, and continuously monitors and participates in every step
            of the development and operational process
          </div>
        </div>
        <div class="item">
          <div class="left"><div class="icon">3</div></div>
          <div class="right">
            Alignment of interests - investors receive all capital and profits
            before the fund receives any
          </div>
        </div>
        <div class="item">
          <div class="left"><div class="icon">4</div></div>
          <div class="right">
            Working with real estate developers with a proven track record of
            success, following rigorous due diligence, and providing audited
            quarterly and annual financial statements
          </div>
        </div>
        <div class="item">
          <div class="left"><div class="icon">5</div></div>
          <div class="right">
            Professional investment managers screen projects to ensure that the
            developer has locked in a bank loan before committing EB-5 capital
          </div>
        </div>
        <div class="item">
          <div class="left"><div class="icon">6</div></div>
          <div class="right">
            Favorable capital structure for EB-5 investors, with a clear "exit
            mechanism" at the end of the investment period prior to the entry of
            funds
          </div>
        </div>
        <div class="item">
          <div class="left"><div class="icon">7</div></div>
          <div class="right">
            EB-5 funds account for a low percentage of the investment in the
            project, to ensure that each investor can obtain the maximization of
            sufficient employment opportunities
          </div>
        </div>
        <div class="item">
          <div class="left"><div class="icon">8</div></div>
          <div class="right">
            Professional and well-known lawyers to escort the whole process
          </div>
        </div>
      </div>
    </div>
    <div class="history">
      <p class="title">
        <span class="van-hairline--bottom">Reliable Track Record</span>
      </p>
      <div class="subtitle">
        100% I-526 and I-829 approved & 100% capital return
      </div>
      <div class="list">
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/us/icon_1.png"
            />
          </div>
          <div class="name">
            A Leader in the EB-5 Industry for 14 Years Total Job Creation 16000+
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/us/icon_2.png"
            />
          </div>
          <div class="name">100% 1-526 and 1-829 Project Approvals</div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/us/icon_3.png"
            />
          </div>
          <div class="name">39 Successfully Compted EB-5 Projects</div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/us/icon_4.png"
            />
          </div>
          <div class="name">$420 Million+ of EB-5 Capital Invested</div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/us/icon_5.png"
            />
          </div>
          <div class="name">800+ Families Helped with lmmigration Process</div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/us/icon_6.png"
            />
          </div>
          <div class="name">
            Capital and Profits Returned from 24 Projects(~$100 million)
          </div>
        </div>
      </div>
    </div>
    <div class="project">
      <p class="title">
        <span class="van-hairline--bottom"
          >JWP U.S. FUND I First JCE Details
        </span>
      </p>
      <div class="list">
        <div class="item">
          <div class="name">Project Name</div>
          <div class="intro">
            Oak Grove Vista Georgia Rental Apartment Project
          </div>
        </div>
        <div class="item">
          <div class="name">Project Overview</div>
          <div class="intro">
            TEA-area rental apartment project totaling 302 luxury apartment
            units
          </div>
        </div>
        <div class="item">
          <div class="name">Location</div>
          <div class="intro">
            1310 Jonesboro Road, McDonald, Henry County, Georgia Close to
            Atlanta metropolitan area.
          </div>
        </div>
        <div class="item">
          <div class="name">JCE Type</div>
          <div class="intro">
            High Unemployment Area Project Enjoys 10% of the annual EB-5 quota
          </div>
        </div>
        <div class="item">
          <div class="name">Investment Amount</div>
          <div class="intro">$800,000</div>
        </div>
        <div class="item">
          <div class="name">EB-5 Quota:</div>
          <div class="intro">41 seats</div>
        </div>
        <div class="item">
          <div class="name">Investment Period</div>
          <div class="intro">2-5 years</div>
        </div>
        <div class="item">
          <div class="name">Developer</div>
          <div class="intro">Vista Residential Partners</div>
        </div>
        <div class="item">
          <div class="name">Construction Status</div>
          <div class="intro">Started in November, 2023</div>
        </div>
        <div class="item">
          <div class="name">Completion</div>
          <div class="intro">Estimated completion October 2025</div>
        </div>
        <div class="item">
          <div class="name">Sales Timeline</div>
          <div class="intro">
            All appartments are expected to be sold in the third quarter of 2026
          </div>
        </div>
        <div class="item">
          <div class="name">Payback</div>
          <div class="intro">Upon sale of project assets</div>
        </div>
      </div>
    </div>
    <div class="Immigration">
      <p class="title">
        <span class="van-hairline--bottom"
          >United States Immigration History
        </span>
      </p>
      <div class="intro">
        The first climax of immigration, which American historians have called
        "the great human migration movement", occurred between 1820 and 1860,
        when the total number of immigrants amounted to 5 million. The
        immigrants came mainly from Western and Northern Europe, including about
        2 million Irish, about 1.7 million Germans, and a large number of
        African slaves who had been trafficked to the United States. There were
        also a few immigrants from Asia, mainly Chinese who came to the United
        States to seek gold. <br />
        <br />
        The second climax of immigration was from 1861 to 1880, when about 5
        million immigrants came to the United States. After the end of the Civil
        War, the U.S. saw the peak of industrialization and a strong demand for
        labor. In order to attract European immigrants to the United States, in
        1864, President Abraham Lincoln lobbied Congress to pass the
        Encouragement of Alien Immigration Act. Secretary of State Seward even
        issued a circular to U.S. diplomats in Europe, asking them to treat
        attracting European immigrants as a top diplomatic priority. In order to
        recruit skilled workers from Europe to work in the U.S., some companies
        also organized recruitment missions to Europe. They placed
        advertisements in many European newspapers and issued pamphlets
        depicting the attractive prospects of the United States for Europeans
        who wished to emigrate. Some companies also provided the necessary
        travel expenses for Europeans who wished to immigrate to the United
        States. <br />
        <br />
        The third climax was from 1881 to 1920, when immigration jumped to 23.5
        million. The peak of immigration was in 1907, when it reached 1.285
        million. During this period, the U.S. government enacted several pieces
        of legislation to restrict European immigration and exclude Asian
        immigrants. While the growth of "old immigrants" from northwestern
        European countries such as Britain, Germany and Sweden was limited, the
        growth of "new immigrants" from southeastern European countries such as
        Italy and Russia was rapid. <br />
        <br />
        As a result of these three waves of immigration, the total population of
        the United States exceeded 100 million for the first time in 1920.
        During the 100-year period from 1820 to 1920, the number of immigrants
        accounted for more than 20% of the annual growth of the U.S. population.
        These three waves of immigrants in the history of the United States not
        only had a profound impact on the formation of the national character of
        the United States of America, but also made the United States in just
        over a hundred years to rise rapidly, replacing the United Kingdom as
        the world's number one economic power, and for the future to become a
        world superpower to lay the foundation. It can be said that the wave of
        immigrants forged the strength of the United States.
      </div>
    </div>
    <div class="bottom">
      <img
        src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/greece/bottom.png"
      />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isFold: true,
      btnText: "View More",
    };
  },
  beforeCreate() {
    document.title = "JWP U.S. FUND I";
  },
  methods: {
    handleLookMore() {
      if (this.isFold) {
        this.isFold = false;
        this.btnText = "Collapse";
      } else {
        this.isFold = true;
        this.btnText = "View More";
      }
    },
  },
};
</script>

<style lang="less" scoped>
img {
  display: block;
}
.page-ireland {
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 360px;
    background: url("//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/us/bg.png")
      no-repeat center center / cover;
    .logo {
      width: 76px;
    }
    .title {
      margin-top: 20px;
      padding-bottom: 36px;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        border-bottom: 2px rgba(255, 255, 255, 0.5) solid;
      }
    }
  }
  .content {
    padding: 16px 22px 0;
    transition: all 0.3s;

    &.fold {
      height: 71px;
      overflow: hidden;
    }

    p {
      font-size: 11px;
      color: rgba(38, 38, 38, 1);
      line-height: 20px;
      text-align: justify;
    }
    img {
      margin: 20px 0 8px;
      width: 50%;
      border: 1px solid #e5e5e5;
    }
    .text {
      font-weight: 500;
      font-size: 11px;
      color: #333333;
      line-height: 13px;
      text-align: justify;
      font-style: normal;
    }
  }
  .textcontent {
    padding: 0px 22px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      margin: 20px 0 8px;
      width: 50%;
      border: 1px solid #e5e5e5;
    }
    .text {
      font-weight: 500;
      font-size: 11px;
      color: #333333;
      line-height: 13px;
      text-align: justify;
      font-style: normal;
    }
  }
  .look-more {
    padding: 20px 18px 0px;
    background: #fff;
    z-index: 10;
    .opacity {
      position: absolute;
      height: 20px;
      width: 100%;
      margin: -40px 0 0px -18px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
      z-index: 10;
    
    }
    .btn {
      margin: 0px 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      border-radius: 4px;
      border: 1px solid rgba(38, 38, 38, 1);
      &:active {
        opacity: 0.8;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: rgba(38, 38, 38, 1);
      }
      img {
        width: 8px;
        margin-left: 8px;
      }
    }
  }

  .strategy {
    padding: 10px 0;

    .title {
      padding-bottom: 10px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .list {
      .item {
        margin: 10px 20px;
        padding: 16px 20px;
        background: #f3f8ff;
        border-radius: 8px;
        .name {
          margin-bottom: 16px;
          text-align: center;
          font-weight: 500;
          font-size: 12px;
          color: #0462c9;
          font-style: normal;
        }
        .intro {
          font-weight: 400;
          font-size: 11px;
          color: #666666;
          line-height: 16px;
          text-align: justify;
          font-style: normal;
        }
      }
    }
  }
  .features {
    padding: 20px 22px 20px;
    text-align: center;
    background: #fafafa;
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .img {
      padding-bottom: 24px;
    }
  }
  .duty {
    .title {
      padding: 20px 20px;
      line-height: 14px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .list {
      padding: 0 20px;
      .item {
        padding: 13px 0;
        .name {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 13px;
          color: #0650d5;
          line-height: 18px;
          text-align: left;
          font-style: normal;
          margin-bottom: 4px;
        }
        .intro {
          font-weight: 400;
          font-size: 10px;
          color: #666666;
          line-height: 14px;
          text-align: left;
          font-style: normal;
        }
      }
    }
  }
  .advantage {
    padding: 10px 0px 0;
    .title {
      padding-bottom: 0px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .list {
      padding: 10px 20px;
      .item {
        padding: 15px 0;
        display: flex;
        align-items: center;
        .left {
          overflow: hidden;

          .icon {
            margin-left: -2px;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            font-style: normal;
            background: #0052d7;
            transform: perspective(40px) rotateX(-30deg);
            padding: 5px 5px 5px 7px;
            margin-right: 10px;
          }

          // width: 20px;
        }
        .right {
          flex: 1;
          font-weight: 400;
          font-size: 11px;
          color: #333333;
          line-height: 13px;
          text-align: left;
          font-style: normal;
        }
      }
    }
  }
  .history {
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .subtitle {
      padding: 0 20px 10px;
      font-weight: 500;
      font-size: 12px;
      color: #0052d7;
      line-height: 17px;
    }
    .list {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        background: #fafafa;
        border-radius: 6px;
        width: 31%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        padding: 20px 10px 10px;
        .icon {
          img {
            width: 50px;
          }
          margin-bottom: 13px;
        }
        .name {
          font-weight: 400;
          font-size: 11px;
          color: #333333;
          line-height: 13px;
          text-align: center;
          font-style: normal;
        }
      }
    }
  }
  .Immigration {
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
  }
  .project {
    padding: 20px 0 0px;
    .title {
      padding-bottom: 10px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .list {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: calc(50% - 5px);
        background: #fafafa;
        border-radius: 6px;
        margin-bottom: 10px;
        padding: 12px 10px;
        .name {
          text-align: center;
          font-weight: 600;
          font-size: 13px;
          color: #0650d5;
          line-height: 13px;
          font-style: normal;
          margin-bottom: 10px;
        }
        .intro {
          text-align: center;
          font-weight: 400;
          font-size: 11px;
          color: #333333;
          line-height: 13px;
          font-style: normal;
        }
      }
    }
  }

  .Immigration {
    padding: 20px 20px 20px;
    background: #fafafa;
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }

    .intro {
      font-weight: 300;
      font-size: 11px;
      color: #333333;
      line-height: 16px;
      text-align: justify;
      font-style: normal;
    }
  }
  .bottom {
    img {
      width: 100%;
    }
  }
}
</style>
